import React, { useEffect } from "react";
import Hero from "../../shared/hero/Hero";
import Stats from "../stats/Stats";
import AboutUs from "../../shared/aboutUs/AboutUs";
import Faq from "../../shared/faq/Faq";
import Footer from "../../shared/footer/Footer";
import Features from "../../shared/featues/Features";
import Newsletter from "../../shared/newsletter/Newsletter";
import Steps from "../../shared/steps/Steps";
import AOS from "aos";
import "aos/dist/aos.css";
const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Hero />
      <AboutUs />
      <Stats />
      <Features />
      <Steps />
      <Newsletter />
      <Faq />
      <Footer />
    </>
  );
};

export default Home;
