import React from 'react';

const StatsCard = ({ icon: Icon, iconSize = 30, iconColor = 'red', value, label }:any) => {
  return (
    <div data-aos="fade-up"data-aos-duration="1000" className="bg-white h-32 w-32 rounded-full flex flex-col items-center justify-center">
      <Icon size={iconSize} color={iconColor} />
      <div className="text-base font-semibold">{value}</div>
      <div className="text-sm font-semibold">{label}</div>
    </div>
  );
};

export default StatsCard;
