import React from "react";
import FeatureCard from "../featureCrad/FeatureCard";
import { chat, date } from "../../../assets";
import FeatureSIdeCard from "../featureCrad/FeatureSIdeCard";

const Features = () => (
  <>
    <div className="relative md:mt-32">
      <div className="who_we_sec">
        <div className="mb-1 ">
          <h6 className="text-lg text-rose-600 font-medium text-center ">
            FEATURES
          </h6>
          <h2 className="text-4xl font-manrope text-center font-bold text-gray-900 leading-[3.25rem]">
            What's in it for you
          </h2>
          <div className="h-1 w-56 bg-rose-400 mb-3 rounded-full mx-auto mt-5"></div>
        </div>
        <div className="flex justify-center items-center gap-7 px-16">
          <FeatureCard img={date} title={"Select your partner"} />
        </div>
      </div>
    </div>
    <div className="relative -mt-32">
      <div className="who_we_sec2 pt-44">
        <div className="flex justify-center items-center gap-7 px-16">
          <FeatureSIdeCard img={chat} title={"Chat with anyone"} />
        </div>
      </div>
    </div>
  </>
);

export default Features;
