import React from "react";
import StatsCard from "../../shared/statsCard/StatsCard";
import {
  IconBuildingSkyscraper,
  IconCloudDownload,
  IconHearts,
} from "@tabler/icons-react";

const Stats = () => {
  return (
    <div className="flex justify-center items-center px-4 sm:px-10 lg:px-56 md:mt-32">
      <div className="how_big h-auto px-5 sm:px-10 py-16 lg:py-32 flex items-center justify-center">
        <div className="how_big_text flex items-center flex-col">
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-xl sm:text-2xl lg:text-3xl font-bold mb-3"
          >
            Find Your Special Someone
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="h-1 w-24 sm:w-40 lg:w-56 bg-rose-400 mb-3 rounded-full"
          ></div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="text-sm sm:text-base lg:text-lg font-base px-4 sm:px-6 lg:px-10 mb-8 lg:mb-16"
          >
            Our presence in the country has changed the concept of intra-city
            travel and made last-mile connectivity affordable for all.
          </div>

          <div className="flex flex-col sm:flex-row gap-4 sm:gap-10 justify-start items-center">
            <StatsCard
              icon={IconCloudDownload}
              iconSize={30}
              iconColor="red"
              value="25M"
              label="Downloads"
            />
            <StatsCard
              icon={IconHearts}
              iconSize={30}
              iconColor="red"
              value="10M"
              label="Success stories"
            />
            <StatsCard
              icon={IconBuildingSkyscraper}
              iconSize={30}
              iconColor="red"
              value="10M"
              label="Cities Presence"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
