import React from "react";
import { mb } from "../../../assets";
import DownloadButton from "../downloadButton/DownloadButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PeopleIcon from "@mui/icons-material/People";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const Hero = () => {
  return (
    <div className="flex flex-wrap justify-around who_we_sec2 md:mt-4">
      <div className="lg:w-1/3 sm:w-full px-4">
        <h1 className="font-bold px-4 text-[30px] leading-tight md:text-start leading-tight text-[45px]  text-gray-700 mt-4">
          Most Trusted
          {/* <br className="hidden lg:inline-block" /> */}
          <span className="block text-[30px] text-rose-500 md:text-start leading-tight text-[45px] text-2xl ">
            Matrimony Service
          </span>
        </h1>
        <p className="text-justify mt-6 mb-6 px-4 text-base md:text-start">
          A trustworthy guide to discover your true love forever with the best
          compatibility. Ensuring you all meaningful and authentic connections.
        </p>
        <div className="mt-4 ms-2 px-4">
          <DownloadButton />
        </div>

        {/*feature section hn*/}
        <div className="mt-8 grid grid-cols-2 gap-6 md:grid-cols-3">
          <div className="flex items-center">
            <div className=" rounded-full p-2 flex items-center justify-center">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg> */}
              <FavoriteIcon sx={{ fontSize: "large", color: "#F5004F" }} />
            </div>
            <div className="whitespace-nowrap">
              <h2 className="font-bold text-xl text-gray-900">Perfect Match</h2>
              {/* <p className="text-gray-500 text-sm">Find your soulmate</p> */}
            </div>
          </div>

          <div className="flex items-center">
            {/* <div className="bg-gradient-to-r text-white rounded-full p-2 flex items-center justify-center">
              <span className="text-sm font-bold">10+</span>
            </div> */}
            <div className="rounded-full p-2 flex items-center justify-center">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg> */}
              <PeopleIcon sx={{ fontSize: "25px", color: "#F5004F" }} />
            </div>
            <div className="whitespace-nowrap">
              <h2 className="font-bold text-lg text-gray-900">Members</h2>
              {/* <p className="text-gray-500 text-sm">Trusted by many</p> */}
            </div>
          </div>

          <div className="flex items-center">
            <div className="bg-gradient-to-r  text-white rounded-full p-2 flex items-center justify-center">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg> */}
              <VerifiedUserIcon sx={{ fontSize: "large", color: "#F5004F" }} />
            </div>
            <div className="whitespace-nowrap">
              <h2 className="font-bold text-lg text-gray-900">
                Verified Profiles
              </h2>
              {/* <p className="text-gray-500 text-sm">Ensuring authenticity</p> */}
            </div>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="max-w-1/2 md:-mt-14 -mt-16 h-fit"
      >
        <img src={mb} style={{ width: "45rem" }} />
      </div>
    </div>
  );
};

export default Hero;
