import { IconArrowUpLeft, IconArrowUpRight } from "@tabler/icons-react";
import React from "react";

const AboutUs = () => {
  return (
    <div id="about" className="relative">
      <section className="who_we_sec text-gray-600 body-font mt-2">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="flex items-center gap-5 lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <div className="flex flex-col gap-10 items-end justify-start">
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                className="h-56 w-44   shadow-gray-600 shadow-md  rounded-br-lg rounded-tr-[6rem] rounded-tl-[5rem] rounded-bl-[5rem] "
                src="https://images.pexels.com/photos/2403569/pexels-photo-2403569.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
              />
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="h-44 w-16  bg-rose-500 rounded-br-[6rem] rounded-tr-lg rounded-tl-[5rem] rounded-bl-[5rem] bg-rose-400"
              />
            </div>
            <div className="flex flex-col gap-10 ">
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="h-12  w-32 rounded-br-[6rem] rounded-tr-[5rem] rounded-tl-[5rem] 
      rounded-bl-lg bg-white"
              />
              <img
                data-aos="fade-up"
                data-aos-duration="1000"
                className="h-88 w-60 -mb-10 shadow-gray-600 rounded-tl-lg rounded-tr-[6rem] rounded-br-[5rem] rounded-bl-[5rem] shadow-lg"
                src="https://images.pexels.com/photos/2496336/pexels-photo-2496336.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
              />
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-duration="1000"
            className="lg:flex-grow ml-4 md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center"
          >
            {/* <h6 className="text-lg text-rose-600 font-medium text-center mb-2">ABOUT US</h6> */}
            <h1 className="title-font sm:text-5xl  font-bold text-3xl mb-4  text-gray-900">
              We Make a
              <br className="hidden lg:inline-block" />
              <span className="text-rose-500"> Perfect Couple</span>
            </h1>
            <div className="h-1 w-56 bg-rose-400 mb-3 rounded-full  mb-5"></div>
            <p className="mb-8 leading-relaxed">
              Just like a perfect match in a relationship, our couple shoes are
              designed to complement each other flawlessly. Step into the world
              of comfort and style together with our exclusive range, perfect
              for those who believe in walking hand-in-hand in every journey of
              life.
            </p>
            <div className="flex justify-center">
              <button
                className="inline-flex text-white  border-0 py-2 px-6 h-12  w-auto bg-rose-500 
          rounded-br-[6rem] rounded-tr-[5rem] rounded-tl-[5rem] 
      rounded-bl-lg text-lg text-center flex justify-center items-center hover:bg-rose-600"
              >
                Know more about us &nbsp;
                <IconArrowUpRight />
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
