import { IconArrowUpRight } from "@tabler/icons-react";

const FeatureSIdeCard = ({ img, title, desc }: any) => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center p-4 md:p-8">
      <div className="lg:flex-grow md:w-1/2 lg:pl-2 md:pl-16 flex flex-col items-center md:items-start text-center md:text-left">
        <h1 className="title-font text-2xl md:text-3xl font-bold mb-4 text-gray-900">
          {title}
        </h1>
        <div className="h-1 w-24 md:w-56 bg-rose-400 mb-3 rounded-full"></div>
        <p className="mb-6 md:mb-8 leading-relaxed text-sm md:text-base w-full md:w-auto">
          Just like a perfect match in a relationship, our couple shoes are
          designed to complement each other flawlessly. Step into the world of
          comfort and style together with our exclusive range, perfect for those
          who believe in walking hand-in-hand in every journey of life.
        </p>
        <div className="flex justify-center md:justify-start">
          <button
            className="inline-flex text-white border-0 py-2 px-4 md:px-6 h-12 w-auto bg-rose-500 
            rounded-br-[6rem] rounded-tr-[5rem] rounded-tl-[5rem] 
            rounded-bl-lg text-sm md:text-lg text-center flex justify-center items-center hover:bg-rose-600"
          >
            Know more about us &nbsp;
            <IconArrowUpRight />
          </button>
        </div>
      </div>
      <img
        className="h-full w-full md:h-66 md:w-[40rem] object-cover mt-4 md:mt-0"
        src={img || "http://localhost:5173/src/assets/images/swipeupApp.png"}
        alt=""
      />
    </div>
  );
};

export default FeatureSIdeCard;
