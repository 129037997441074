import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logoSvg from "../../../assets/images/logosvg.svg";

const NewNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleLinkClick = () => {
    if (isOpen) {
      toggleNavbar();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="sticky top-0 z-50">
      <nav
        className={
          !isScrolled ? "bg-transparent" : "bg-white navbar-with-box-shadow"
        } // Fixed height for the navbar
      >
        <div className="max-w-screen-2xl flex items-center  justify-between md:h-full">
          <div className="flex items-center w-56 h-20 p-2">
            <img src={logoSvg} className="" alt="MM Logo" />
          </div>

          <button
            onClick={toggleNavbar}
            type="button"
            className="inline-flex items-center mx-4 w-10 h-10 justify-center text-sm text-pink-600 rounded-lg md:hidden hover:bg-pink-200 focus:outline-none focus:ring-2 focus:ring-pink-400"
            aria-controls="navbar-default"
            aria-expanded={isOpen ? "true" : "false"}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`${
              isOpen ? "fixed top-16 left-0 right-0 z-50" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-default"
          >
            {/* Updated navigation links */}
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-pink-200 rounded-lg bg-white/95 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent">
              <li>
                <Link
                  onClick={handleLinkClick}
                  to="/"
                  className="block md:pt-2 py-2 px-3 text-pink-600 rounded transition-transform duration-300 hover:scale-105 hover:shadow-xl hover:shadow-pink-300/40 md:hover:bg-transparent md:border-0 md:hover:text-pink-700 md:p-3"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleLinkClick}
                  to="/pricing"
                  className="block md:pt-2 py-2 px-3 text-pink-600 rounded transition-transform duration-300 hover:scale-105 hover:shadow-xl hover:shadow-pink-300/40 md:hover:bg-transparent md:border-0 md:hover:text-pink-700 md:p-3"
                >
                  Pricing
                </Link>
              </li>
              {/* <li>
                <Link
                  onClick={handleLinkClick}
                  to="/privacy"
                  className="block md:pt-2 py-2 px-3 text-pink-600 rounded transition-transform duration-300 hover:scale-105 hover:shadow-xl hover:shadow-pink-300/40 md:hover:bg-transparent md:border-0 md:hover:text-pink-700 md:p-3"
                >
                  Privacy Policy
                </Link>
              </li> */}
              <li>
                <Link
                  onClick={handleLinkClick}
                  to="/about"
                  className="block md:border-2 px-3 py-2 md:rounded-br-lg md:rounded-tr-[5rem] md:rounded-tl-[5rem] md:rounded-bl-[6rem] md:border-rose-500 
      md:text-gray-900 md:bg-transparent text-pink-600 md:bg-rose-500 hover:shadow-xl hover:shadow-pink-300/40"
                >
                  Know Us
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleLinkClick}
                  to="/contact"
                  className="block md:bg-rose-500 px-3 py-2  mr-4 md:rounded-br-[4rem] md:rounded-tr-[5rem] md:rounded-tl-[5rem] md:rounded-bl-lg 
      md:text-white text-pink-600 hover:shadow-xl hover:shadow-pink-300/40"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NewNavbar;
