import React from 'react'

const Mainpage:React.FC=()=> {
  return (
    <div className='container mx-auto mb-4    max-w-[1320px]  rounded bg-white shadow-md border mt-4 border-gray-300 text-center p-8'>
        <div className='  text-4xl '>
       <h3 className='font-semibold text-red-500'>Welcome to Muslim Matrimony - Find Your Perfect Life Partner</h3>
       <p className='text-justify text-lg text-gray-800 py-2 leading-8'>
               
         Are you a Muslim individual searching for a life partner who shares your faith and values?
        Look no further! Muslim Matrimony is an innovative and user-friendly app designed exclusively for the Muslim community,
        offering a seamless and secure platform to connect and find your ideal match.
        </p>
             
        </div>
        <div className='text-4xl'>
        <h3 className=' text-red-500 font-semibold'>Why Choose Muslim Matrimony?</h3>
        <ul className="mb-10 mt-4">
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className=""> Exclusive Muslim Community: Our app is dedicated to Muslims worldwide, providing a focused and tailored 
                approach to finding compatible partners within the community. </div>
                </div>
        </li>
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className="">  Advanced Matching Algorithms: Our intelligent matchmaking algorithms analyze your preferences, interests, and compatibility factors to suggest highly 
                compatible profiles, increasing the likelihood of finding a perfect match.  </div>
                </div>
        </li>
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className="">  Secure and Private: We prioritize your privacy and ensure the utmost security of your personal information. Our platform employs strict verification 
                processes and takes measures to maintain a safe environment for all users.  </div>
                </div>
        </li>
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className="">  Detailed Profiles: Gain insights into potential matches through comprehensive profiles that highlight important aspects such as religious beliefs, education, profession, family background, and more. This allows
                 you to make informed decisions while considering a prospective partner.  </div>
                </div>
        </li>
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className="">  Enhanced Communication: Engage in meaningful conversations with other members through our secure messaging feature. Get to know each other better, share 
                your values and aspirations, and build a strong foundation for a lifelong relationship.  </div>
                </div>
        </li>
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className="">  Community and Support: Join a vibrant community of like-minded individuals who understand the importance of faith and cultural values. Interact through forums, discussion boards, 
                and social events, fostering connections beyond the traditional matchmaking experience.  </div>
                </div>
        </li>
</ul>
</div>

{/* third part started here */}
<div className='text-4xl'>
        <h3 className=' text-red-500 font-semibold'>How It Works?</h3>
        <ul className="mb-10 mt-4">
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className=""> Sign Up: Create Your Profile By Providing Essential
                 Details And Preferences That Will Help Us Understand Your Requirements Better. </div>
                </div>
        </li>
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className=""> Explore Profiles: Browse Through A Vast Database Of Verified Profiles, 
                Filtering Based On Specific Criteria Such As Age, Location, Education, And More. </div>
                </div>
        </li>
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className=""> Connect And Communicate: Express Interest, 
                Send Personalized Messages, And Initiate Conversations With Potential Matches.  </div>
                </div>
        </li>
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className="">  Get To Know Each Other: Engage In Meaningful Conversations, 
                Exchange Photos, And Build A Connection To Assess Compatibility.</div>
                </div>
        </li>
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className="">  Take The Next Step: When You Find Someone Special, Take The Relationship Forward By 
                Involving Families, Conducting Meetings, And Progressing Towards A Joyous Union.  </div>
                </div>
        </li>
        <li className="flex  items-center mb-4 text-lg text-justify text-gray-800 dark:text-gray-400">
            <span className="mr-3 text-gray-500 dark:text-gray-400 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="w-5 h-5 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
            </span>

            <div className="flex flex-col">
                <div className="">  Join Muslim Matrimony Today And Embark On 
                A Beautiful Journey To Find Your Life Partner Who Shares Your Values, Beliefs, And Aspirations. Experience The Convenience, Privacy, And Effectiveness Of Our Platform, Designed Exclusively For Muslims Seeking Lifelong Companionship.
                Sign Up Now And Let Love Find Its Way! </div>
                </div>
        </li>
</ul>
</div>
        </div>
  )
}

export default Mainpage