const Steps = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.2,
        duration: 0.6,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      <section className="pb-10 md:pt-32 mt-5">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div>
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl">
                How to Find{" "}
                <span className=" text-rose-500">Your Partner?</span>
              </h2>

              <p className="max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600">
                Find your best Partner at 3 simple steps
              </p>
            </div>

            <div className="relative mt-12 lg:mt-20">
              <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                <img
                  className="w-full"
                  src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg"
                  alt=""
                />
              </div>

              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12"
              >
                <div>
                  <div className="flex items-center justify-center w-16 h-16 mx-auto bg-rose-500 border-2 border-gray-200 rounded-full shadow">
                    <span className="text-xl font-semibold text-gray-100">
                      1
                    </span>
                  </div>
                  <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                    Download the app
                  </h3>
                  <p className="mt-4 text-base text-gray-600">
                    Choose your favorite person according to your desire we have
                    variety
                  </p>
                </div>

                <div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    className="flex items-center justify-center w-16 h-16 mx-auto bg-rose-500 border-2 border-gray-200 rounded-full shadow"
                  >
                    <span className="text-xl font-semibold text-gray-100">
                      2
                    </span>
                  </div>
                  <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                    Create an account
                  </h3>
                  <p className="mt-4 text-base text-gray-600">
                    Choose your favorite person according to your desire we have
                    variety
                  </p>
                </div>

                <div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    className="flex items-center justify-center w-16 h-16 mx-auto bg-rose-500 border-2 border-gray-200 rounded-full shadow"
                  >
                    <span className="text-xl font-semibold text-gray-100">
                      3
                    </span>
                  </div>
                  <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                    Start chatting
                  </h3>
                  <p className="mt-4 text-base text-gray-600">
                    Choose your favorite person according to your desire we have
                    variety
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Steps;
