import "./DownloadButton.css";
const DownloadButton = () => {
  return (
    /* From Uiverse.io by themrsami */
    <button
      className="glowing-button font-bold cursor-pointer flex justify-between bg-rose-500 px-3 py-4 mt-4
  rounded-br-[6rem] rounded-tr-[6rem] rounded-tl-[5rem] rounded-bl-lg text-white tracking-wider 
  shadow-2xl hover:bg-gray-900 hover:scale-105 duration-500 hover:ring-1 w-[180px]"
    >
      &nbsp; Free Download
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        className="w-5 h-5 animate-bounce"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
        ></path>
      </svg>
    </button>
  );
};

export default DownloadButton;
